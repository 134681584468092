import $ from "jquery";

$(() => {
  const $accordionTabs = $('.accordion > .tab');
  $accordionTabs.addClass('closed');
  $accordionTabs.find('.tab-content-wrap').css('height', 0);

  const toggleTab = (tab, to) => {
    if (typeof to === 'undefined') to = tab.hasClass('closed');
    const animate = to === tab.hasClass('closed');
    tab.toggleClass('closed', !to);
    const panel = tab.children('.tab-content-wrap');
    if (!animate) return;

    if (to) {
      panel.stop().css('height', 'auto');
      const height = panel.outerHeight();
      panel.css('height', 0).animate({ height }, 400, undefined, () => panel.css('height', 'auto'));
    } else {
      panel.stop().animate({ height: 0 }, 400, undefined);
    }
  };

  $('body').on('click', '.accordion > .tab > .tab-title', function () {
    const tab = $(this).closest('.tab');
    const accordion = tab.closest('.accordion');
    accordion.find('> .tab').not(tab).each(function () {
      toggleTab($(this), false);
    });
    toggleTab(tab);
  });
});