import React, { useState, useEffect, useRef } from "react";
import NavSubItem from "./NavSubItem.jsx";
import { motion, AnimatePresence } from "framer-motion";

export default function NavPopups({ path, openPath }) {
    const [submenuHeight, setSubmenuHeight] = useState(0);
    const submenuRef = useRef(null);

    useEffect(() => {
        // recalculate height whenever the path or submenu content changes
        if (submenuRef.current) {
            let height = submenuRef.current.scrollHeight;
            // avoid paddings inside scrollHeight
            if (path.length == 0) {
                height = 0;
            }
            setSubmenuHeight(height);
        }
    }, [path]);

    const currentMenu = path[path.length - 1]?.children || [];

    const handleBack = () => {
        openPath(path.slice(0, -1));
    };

    const handleMouseEnter = () => {
        openPath(path);
    };

    const handleMouseLeave = () => {
        openPath([]);
    };

    return (
        <motion.div
            className="popup-navigation"
            style={{ height: submenuHeight }}
            initial={{ height: 0 }}
            animate={{ height: submenuHeight }}
            exit={{ height: 0 }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <AnimatePresence>
                {path.length > 1 && (
                    <motion.div
                        key="back-button"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="buttons"
                    >
                        <button className="back-arrow btn btn-link" onClick={handleBack}>
                            <span className="ion ion-md-arrow-back mr-2" />
                        </button>
                    </motion.div>
                )}
            </AnimatePresence>
            <motion.div
                key="submenu"
                ref={submenuRef}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="submenu visible first-level"
            >
                <ul className={`items-lt-${currentMenu.length}`}>
                    {currentMenu.map((item) => (
                        <NavSubItem
                            key={item.id}
                            item={item}
                            path={path}
                            openPath={openPath}
                        />
                    ))}
                </ul>
            </motion.div>
        </motion.div>
    );
};