import React, { useState, useEffect, useRef } from 'react';

function distance(lat1, lon1, lat2, lon2) {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p) / 2 +
        c(lat1 * p) * c(lat2 * p) *
        (1 - c((lon2 - lon1) * p)) / 2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

export default function Searchbar({ l10n, locations, query: initialQuery, maps, onLocate, onSelect, position, ...props }) {
    const [query, setQuery] = useState(initialQuery || '');
    const [searchExecuted, setSearchExecuted] = useState(false);
    const initialQueryProcessedRef = useRef(false);

    useEffect(() => {
        if (initialQuery && !initialQueryProcessedRef.current) {
            initialQueryProcessedRef.current = true;
            locate();
        }
    }, [initialQuery]);

    const locate = (mapsInstance = maps) => {
        if (!mapsInstance || !query) return;

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: query, region: 'DE' }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                onLocate && onLocate([lat, lng], query);
                setSearchExecuted(true);
            }
        });
    };

    const geolocate = () => {
        navigator.geolocation && navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
            onLocate && onLocate([latitude, longitude]);
            setSearchExecuted(true);
        });
    };

    const select = (location) => {
        onSelect && onSelect(location);
    };

    // Only calculate topLocations when position is available
    const topLocations = position ? locations.map(l => {
        let { lat: lat1, lng: lng1 } = l.location;
        let [lat2, lng2] = position; // Safe now since position is checked
        return {
            ...l,
            original: l,
            distance_approx: distance(lat1, lng1, lat2, lng2)
        };
    }).sort((a, b) => a.distance_approx - b.distance_approx).slice(0, 10) : [];

    const { setQuery: _, ...filteredProps } = props;

    return (
        <div className={`feg_map_searchbar d-none d-sm-flex`} {...filteredProps}>
            <h4>{l10n.title}</h4>
            <form className='form-inline d-flex mt-3' onSubmit={e => { e.preventDefault(); locate(); }}>
                <input
                    className='form-control mr-sm-2'
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder={l10n.plz_ort}
                />
                <button className='btn btn-primary' type='submit'><div className='ion ion-md-search' /></button>
            </form>
            {position && searchExecuted && (
                <ul className='locations nav d-none d-md-flex'>
                    {topLocations.map(l =>
                        <li onClick={() => select(l.original)} className='nav-item'>
                            <div className='d-flex align-items-start justify-content-between flex-row'>
                                <h6 className='mb-1'>{l.name}</h6>
                                <span className='badge badge-pill badge-primary km ml-g'>{Math.round(l.distance_approx)} km</span>
                            </div>
                            <p>{l.location.address}</p>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
}