import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import NavBar from "./NavBar.jsx";
import NavPopup from "./NavPopup.jsx";

const isMobile = () => window.innerWidth < 1020;

export default function NavContainer({ navItems, togglers }) {
    const [path, setPath] = useState([]);
    const [mobile, setMobile] = useState(isMobile());
    const [mobileOpen, setMobileOpen] = useState(false);
    const containerRef = useRef(null);
    let hideTimeoutRef;

    useEffect(() => {
        const handleResize = () => {
            setMobile(isMobile());
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const handleTogglerClick = () => {
            setMobileOpen((prev) => {
                if (prev) {
                    setPath([]);
                }
                return !prev;
            });
        };

        Array.from(togglers).forEach(toggler => {
            toggler.addEventListener('click', handleTogglerClick);
        });

        return () => {
            Array.from(togglers).forEach(toggler => {
                toggler.removeEventListener('click', handleTogglerClick);
            });
        };
    }, [togglers]);

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         if (
    //             containerRef.current &&
    //             !containerRef.current.contains(event.target) &&
    //             !Array.from(togglers).some(toggler => toggler.contains(event.target)) &&
    //             !event.target.closest('.nav-item')
    //         ) {
    //             setMobileOpen(false);
    //             setPath([]);
    //         }
    //     };

    //     if (mobileOpen) {
    //         document.addEventListener("click", handleClickOutside);
    //     } else {
    //         document.removeEventListener("click", handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener("click", handleClickOutside);
    //     };
    // }, [mobileOpen]);

    const openPath = (newPath) => {
        if (newPath.length == 0) {
            hideTimeoutRef = setTimeout(() => setPath([...newPath]), 200);
        } else {
            clearTimeout(hideTimeoutRef);
            setPath([...newPath]);
        }
    };

    const mobileNavRoot = {
        name: "",
        url: "",
        parents: [],
        hasChildren: true,
        children: navItems,
    };

    const currentPath = (
        mobile && mobileOpen ? [mobileNavRoot] : []
    ).concat(path);

    return (
        <div ref={containerRef} className="nav-container">
            <NavBar items={navItems} path={currentPath} openPath={openPath} />
            <NavPopup path={currentPath} openPath={openPath} />
        </div>
    );
};

NavContainer.propTypes = {
    navItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            url: PropTypes.string,
            active: PropTypes.bool,
            activeAncestor: PropTypes.bool,
            hasChildren: PropTypes.bool,
            children: PropTypes.array,
        })
    ).isRequired,
};