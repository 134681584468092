import React from "react";
import NavItem from "./NavItem.jsx";

export default function NavBar({ items, path, openPath }) {
    return (
        <ul className={`navbar-nav d-none d-lg-flex ${path.length ? "open" : ""}`}>
            {items.map((item) => (
                <NavItem key={item.id} item={item} path={path} openPath={openPath} />
            ))}
        </ul>
    );
};
