import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import Searchbar from './Searchbar.jsx';

export default function MapControl({ map, controlPosition, locations, l10n, query, setQuery, onLocate, onSelect, position, searchbar }) {
  const controlDivRef = useRef(null); // Reference to the DOM element
  const rootRef = useRef(null);       // Reference to the React root
  const prevMapRef = useRef(null);    // Track the previous map instance

  useEffect(() => {
    if (typeof google === 'undefined' || !map) return;

    // Only create and attach the control if it doesn’t already exist or if the map has changed
    if (!controlDivRef.current || prevMapRef.current !== map) {
      // Clean up the previous control if the map instance changed
      if (prevMapRef.current && controlDivRef.current) {
        const prevIndex = prevMapRef.current.controls[controlPosition].getArray().indexOf(controlDivRef.current);
        if (prevIndex > -1) prevMapRef.current.controls[controlPosition].removeAt(prevIndex);
      }

      const controlDiv = document.createElement('div');
      controlDiv.className = 'feg_map_control_fullheight';
      controlDivRef.current = controlDiv;
      map.controls[controlPosition].push(controlDiv);

      // Create a React root for rendering
      rootRef.current = createRoot(controlDiv);
      prevMapRef.current = map;
    }

    // Render or update the Searchbar only when necessary
    const shouldRenderSearchbar = searchbar === true;
    if (rootRef.current) {
      rootRef.current.render(
        shouldRenderSearchbar ? (
          <Searchbar
            l10n={l10n}
            locations={locations}
            query={query}
            setQuery={setQuery}
            maps={map}
            onLocate={onLocate}
            onSelect={onSelect}
            position={position}
          />
        ) : null
      );
    }

    // Cleanup function to remove the control when the component unmounts or map changes
    return () => {
      if (map && controlDivRef.current) {
        const index = map.controls[controlPosition].getArray().indexOf(controlDivRef.current);
        if (index > -1) map.controls[controlPosition].removeAt(index);
      }
      // Note: We don’t unmount the root here to avoid flicker; let React handle it
    };
  }, [map, controlPosition, searchbar]); // Only re-run if map, controlPosition, or searchbar changes

  // Use a separate effect to update Searchbar props without re-adding the control
  useEffect(() => {
    if (rootRef.current && searchbar === true) {
      rootRef.current.render(
        <Searchbar
          l10n={l10n}
          locations={locations}
          query={query}
          setQuery={setQuery}
          maps={map}
          onLocate={onLocate}
          onSelect={onSelect}
          position={position}
        />
      );
    }
  }, [locations, l10n, query, setQuery, onLocate, onSelect, position]); // Update when these props change

  return null;
}