import $ from 'jquery';

const idGen = (() => {
    let i = 0
    return () => {
        i++
        return i
    }
})();

export default function getElements(ul, parents = []) {
    return ul.children().map((i, el) => {
        let link = $(el).children('a')
        let result = {
            id: idGen(),
            name: link.text(),
            url: link.attr('href'),
            active: link.parent().hasClass('active'),
            activeAncestor: link.parent().hasClass('active-ancestor'),
            parents
        }
        result.children = getElements($(el).children('ul'), [...parents, result])
        result.hasChildren = result.children.length > 0
        return result
    }).toArray()
}