import React from 'react';
import { createRoot } from 'react-dom/client';
import $ from 'jquery';
import NavContainer from './comps/Nav/Nav.jsx';
import Map from './comps/Map/Map.jsx';
import getElements from './utils/utils.js';

const navItems = getElements($('#navbar ul.navbar-nav'));
if (document.getElementById('navbar')) {
    const root = createRoot(document.getElementById('navbar'));
    const togglers = document.getElementsByClassName('navbar-toggler');
    root.render(<NavContainer navItems={navItems} togglers={togglers}/>);
}

// map
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.feg_map').forEach((el) => {
        const root = createRoot(el);
        // Handle missing data-locations gracefully
        let locations = [];
        const locationsAttr = el.getAttribute('data-locations');
        if (locationsAttr) {
            try {
                locations = JSON.parse(locationsAttr);
            } catch (e) {
                console.error('Invalid data-locations JSON:', e);
            }
        } else {
            // Use data-lat and data-lng if provided
            const lat = el.getAttribute('data-lat');
            const lng = el.getAttribute('data-lng');
            if (lat && lng) {
                locations = [{ location: { lat, lng } }];
            }
        }

        // Handle missing data-l10n gracefully
        let l10n = { no_data: "No locations found" }; // Default fallback
        const l10nAttr = el.getAttribute('data-l10n');
        if (l10nAttr) {
            try {
                l10n = JSON.parse(l10nAttr);
            } catch (e) {
                console.error('Invalid data-l10n JSON:', e);
            }
        }

        const searchquery = el.getAttribute('data-searchquery');
        const searchbar = el.getAttribute('data-searchbar') || "false";
        const zoom = el.getAttribute('data-zoom');
        const pincolor = el.getAttribute('data-pincolor');

        root.render(
            <Map
                locations={locations}
                l10n={l10n}
                searchquery={searchquery}
                searchbar={searchbar}
                zoom={zoom}
                pincolor={pincolor}
            />
        );
    });
});