import $ from "jquery";
window.$ = $;
window.jQuery = $;
import 'slick-carousel';
import 'lightbox2';

export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 820,
  lg: 1020,
  xl: 1500
};

let me, slider;

let visible = false;

const close = () => {
  if (!slider) return;
  if (!visible) return;
  visible = false;
  me.removeClass('open');
};

const open = () => {
  if (!slider) return;
  if (visible) return;
  visible = true;
  me.addClass('open');
};

const show = (content, startAt) => {
  if (!slider) return;
  if (!Array.isArray(content)) content = [content];
  startAt = startAt || 0;
  // Remove all slides – Undocumented but works
  slider.slick('removeSlide', null, null, true);
  $.each(content, (i, c) =>
    slider.slick('slickAdd', `<div class="slide"><div class="container"><div class="content">${c}</div></div></div>`)
  );
  slider.slick('slickGoTo', startAt, true);
  setTimeout(open);
};

// Lightbox
$(function () {
  me = $('<div class="lightbox" />').appendTo('body');

  me.on('dragstart', 'img', e => e.preventDefault());

  slider = $('<div class="lightbox-slider" />').appendTo(me).slick({
    autoplay: false,
    accessibility: false, // Wir kümmern uns selber um die Tastaturbedienung
    prevArrow: '<button type="button" class="slick-prev btn btn-primary"><div class="ion ion-md-arrow-back"></div></button>',
    nextArrow: '<button type="button" class="slick-next btn btn-primary"><div class="ion ion-md-arrow-forward"></div></button>',
  });

  me.click(function (e) {
    let target = (e.originalEvent || e).target;
    if ($(target).is('.content, .content *, .slick-arrow, .slick-arrow *')) return;
    close();
  });

  $('<div class="close icon ic-remove" />').click(close).appendTo(me);
  $(document).keyup(function (e) {
    if (!visible) return;
    let { code } = (e.originalEvent || e);
    if (code == "Escape") close();
    if (code == "ArrowRight") slider.slick('slickNext');
    if (code == "ArrowLeft") slider.slick('slickPrev');
  });
});

const limit = (a, b) => x => Math.min(b, Math.max(a, x));

const height = (min, max, factor) => width => limit(min, max)(factor * width);
const heights = {
  normal: height(300, 600, 0.5),
  small: height(250, 500, 0.35)
};

jQuery($ => {
  const resizeSlides = (container = 'body') => {
    $(container).find('.slide').each((i, slide) => {
      let minHeight = ($(slide).hasClass('slide-small-height') ? heights.small : heights.normal)($(slide).outerWidth());
      $(slide).css('min-height', minHeight).find('> .container').css('min-height', minHeight);
    });
  };

  $(window).resize(() => resizeSlides());
  resizeSlides();

  $('.slider').each((i, el) => {
    let container = $('<div class="container-fluid arrow-container">').insertAfter(el);

    $(el).on('setPosition', () => resizeSlides(el));

    $(el).slick({
      prevArrow: '<button type="button" class="slick-prev btn btn-primary"><div class="ion ion-md-arrow-back"></div></button>',
      nextArrow: '<button type="button" class="slick-next btn btn-primary"><div class="ion ion-md-arrow-forward"></div></button>',
      appendArrows: container,
      dots: true
    });
  });

  function responsive(xs, sm, md, lg) {
    return [
      {
        breakpoint: BREAKPOINTS.xl - 1,
        settings: {
          slidesToShow: lg,
          slidesToScroll: lg
        }
      },
      {
        breakpoint: BREAKPOINTS.lg - 1,
        settings: {
          slidesToShow: md,
          slidesToScroll: md
        }
      },
      {
        breakpoint: BREAKPOINTS.md - 1,
        settings: {
          slidesToShow: sm,
          slidesToScroll: sm
        }
      },
      {
        breakpoint: BREAKPOINTS.sm - 1,
        settings: {
          slidesToShow: xs,
          slidesToScroll: xs,
          dots: true
        }
      }
    ];
  }

  $('.feg_gallery-slider').each((i, slider) => {
    var slides = 4;
    var bps = responsive(1, 2, 2, 3);
    if ($(slider).hasClass('feg_gallery-slider-6-col')) {
      var slides = 6;
      var bps = responsive(1, 2, 3, 4);
    }
    $(slider).slick({
      slidesToShow: slides,
      slidesToScroll: slides,
      infinite: false,
      prevArrow: '<button type="button" class="slick-prev btn btn-link"><div class="ion ion-md-arrow-back"></div></button>',
      nextArrow: '<button type="button" class="slick-next btn btn-link"><div class="ion ion-md-arrow-forward"></div></button>',
      responsive: bps
    });
  });

  $('body').on('click', '.feg_gallery-item a', function (e) {
    let item = $(this).parent();
    let gallery = item.closest('.feg_gallery');
    if (!gallery.hasClass('feg_gallery_lightbox')) return;
    e.preventDefault();
    let items = gallery.find('.feg_gallery-item');
    let images = items.map((i, el) => {
      let img = $(el).find('img').get(0);
      return img ? img.outerHTML : '<div class="placeholder"></div>';
    }).toArray();

    show(images, items.index(item));
  });
});
