import React from "react";

export default function NavItem({ item, path, openPath }) {
    const isOpen = path.includes(item);

    const handleMouseEnter = () => {
        if (item.hasChildren) {
            openPath([...item.parents, item]);
        }
    };

    const handleMouseLeave = () => {
        if (item.hasChildren) {
            openPath([]);
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        if (item.hasChildren) {
            openPath([...item.parents, item]);
        } else if (item.url) {
            if (e.ctrlKey || e.metaKey) {
                window.open(item.url, '_blank');
            } else {
                window.location = item.url;
            }
        }
    };

    return (
        <li
            className={`nav-item ${item.active ? "active" : ""} ${isOpen ? "open" : ""}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <a href={item.url} className="nav-link" onClick={handleClick}>
                {item.name}
            </a>
        </li>
    );
};
